import { Input } from '@rebass/forms';
import { GoogleApiWrapper } from 'google-maps-react';
import React, { useEffect, useRef } from 'react';

interface ILoadingSearchContainer {
  google: any,
  callback: Function,
}
const LocationSearchContainer = ({ google, callback }: ILoadingSearchContainer) => {
  const iRef = useRef(null);
  useEffect(() => {
    if (iRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(iRef.current);
      autocomplete.addListener("place_changed", () => {
        callback(autocomplete.getPlace().geometry.location.lat(), autocomplete.getPlace().geometry.location.lng());
      });
    }
  }, [callback, google.maps.places.Autocomplete, iRef])



  return (
    <>
      <Input
        bg="white"
        color="dark"
        ref={iRef}
      />
    </>
  )
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDv4rErWh9VeL_GffbaDOinZuSZ7vJJicY',
})(LocationSearchContainer)