import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { ThemeProvider } from 'theme-ui';


import './main.css';
import HomeQuote from './pages/HomeQuote';
import { theme } from './theme/theme';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Route exact path="/" component={HomeQuote} />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
