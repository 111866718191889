import React from 'react';
import './HomeQuote.scss';
import '../../App.scss';
import { Box, Button, Card, Flex, Heading, Text } from 'rebass';
import { Label, Input } from '@rebass/forms';
import Spinner from 'react-spinkit';
import LocationSearch from '../../components/LocationSearch';
import { theme } from '../../theme/theme';
import truck from './download.svg';

interface ILoc {
  lat?: number | null, lng?: number | null
}
interface IHomeQuoteview {
  start: Array<number>,
  end: Array<number>,
  setValues: Function,
  equip: {[key: string]: boolean}
  setEquip: Function,
  submit: Function,
  result: any,
  resultState: string,
  email?: string,
  setEmail: Function,
  allValid: boolean,
  emailValid: boolean,
}

const HomeQuoteView = ({ start, end, setValues, equip, setEquip, submit, result, resultState, email, setEmail, allValid, emailValid }: IHomeQuoteview) => (
  <>
    <Box sx={{ zIndex: 0, left: 0, top: 0, position: "absolute", height: '33vh', width: '100%', backgroundColor: theme.colors.primary}}></Box>
    <Box sx={{ zIndex: 1, position: 'relative'}} px={[3,3,0]} minHeight="100vh" pb={5}>
      <Box mx="auto" className="container pt-4" color="white">
        <Text fontFamily="monospace" fontWeight="bold" className="text-2xl">truckist</Text>
        <Text className="text-xs font-light">Powered by Spaceship</Text>
      </Box>
      <Box sx={{ zIndex: 2}} mx="auto" mt={4} className="container">
        <Box color="white" mb={4}>
          {/* <Text variant="heading" fontSize={5}>Instant Quote</Text> */}
        </Box>
          <Flex mx={-2} flexWrap="wrap-reverse">
            <Box p={2} width={[1, 1, 1/2]}>
              <Card height="100%" sx={{ boxShadow: '0em 0.2em 0.2em rgba(0,0,0,0.15)', borderTop: `4pt solid ${theme.colors.purple}`}} bg="white" color="primary" p={4}>
                <Box mb={2}>
                  <Heading>Market Quote</Heading>
                  <Text variant="label">Email*</Text>
                  <Input value={email} onChange={(e) => setEmail(e.target.value)} bg="white" color="dark" placeholder="Email Address"></Input>
                  { email && !emailValid && (<Label>Enter a valid email</Label>)}
                </Box>
                <Flex flexWrap="wrap" mx={-2}>
                  <Box mb={2} width={[1, 1, 1/2]} px={2}>
                    <Text variant="label">Origin*</Text>
                    <LocationSearch
                      callback={(lat: number, lng: number) => {setValues("start", lat, lng)}}
                    />
                  </Box>
                  <Box mb={2} width={[1, 1, 1/2]} px={2}>
                    <Text variant="label">Destination*</Text>
                    <LocationSearch
                      callback={(lat: number, lng: number) => {setValues("end", lat, lng)}}
                    />
                  </Box>
                </Flex>
                <Box mt={2}>
                  <Flex justifyContent="space-between" flexWrap="wrap" alignItems="flex-end">
                    {/* <Box width={[2, 2, 1/2]} my={2}>
                      <Label>Equipment</Label>
                      <Button onClick={() => setEquip('van')} py={0} mr={2} sx={{ borderRadius: 0, border: '1pt solid #fff', background: equip['van'] ? '#3A3978': 'none' }}>Van</Button>
                      <Button onClick={() => setEquip('flatbed')} py={0} mr={2} sx={{ borderRadius: 0, border: '1pt solid #fff', background: equip['flatbed'] ? '#3A3978': 'none' }}>Flatbed</Button>
                      <Button onClick={() => setEquip('reefer')} py={0} sx={{ borderRadius: 0, border: '1pt solid #fff', background: equip['reefer'] ? '#3A3978': 'none' }}>Reefer</Button>
                    </Box> */}
                    <Box width={[1, 1, 1/2]}>
                      <Button bg={allValid ? "primary" : "light"} disabled={!allValid} variant="outline" onClick={() => { if (allValid) submit() }} width={[1, 1, 'auto']} >Get Quote</Button>
                    </Box>
                  </Flex>
                </Box>
              </Card>
            </Box>
            <Box p={2} width={[1, 1, 1/2]}>
              <Card sx={{ boxShadow: '0em 0.2em 0.2em rgba(0,0,0,0.15)', borderTop: `4pt solid ${theme.colors.purple}`}} bg="white" color="primary" p={4}>
                <img width="20%" src={truck} alt="truck"/>
                <Heading mt={3}>Instant Freight Quotes</Heading>
                <Text>Powered by Spaceship, Truckist can give you instant truckload quotes for anywhere in the United States</Text>
                <Text>Get market insights into the costs of freight.</Text>
                <Text>Try the instant quote for an example of our automaation. The quotes are real-time market aggregate quotes.</Text>
              </Card>
            </Box>
          </Flex>
        { resultState !== "INIT" && (
          <Card sx={{ boxShadow: '0em 0.2em 0.2em rgba(0,0,0,0.15)', borderTop: `4pt solid ${theme.colors.purple}`}} bg="white" color="primary" p={4}>
            { resultState === "LOADING" && (
              <Box width="100%">
                <Flex alignItems="center" justifyContent="center" flexDirection="column">
                  <Spinner name="cube-grid" color={theme.colors.primary}/>
                  <Text mt={2} color="primary">We're generating your quote</Text>
                </Flex>
              </Box>
            )}
            { resultState === "LOADED" && (
              <>
              <Box>
                <Box>
                  {(() => { 
                    if (result.SampleSize < 15) {
                      return (<Box mb={2} sx={{ width: "max-content"}} p={1} bg="warning" color="white">Low Confidence</Box>)
                    } else if (result.SampleSize > 30) {
                      return (<Box mb={2} sx={{ width: "max-content"}} p={1} bg="success" color="white">High Confidence</Box>)
                    }
                  })()}
                </Box>
                <Heading>Your Estimated Price</Heading>
                <Text my={2} fontFamily="monospace" className="text-xl">${(result.Mean * 1.15).toFixed(2)} +/- ${result.Confidence.Z90}</Text>
              </Box>
              <Box>
                <Heading>Breakdown</Heading>
                <Text fontFamily="monospace">Cost: ${result.Mean.toFixed(2)}</Text>
                <Text fontFamily="monospace" my={2}>Estimated Commission: ${(result.Mean * 0.138).toFixed(2)}</Text>
              </Box>
              </>
            )}
          </Card>
        )}
        <Card mt={2} sx={{ boxShadow: '0em 0.2em 0.2em rgba(0,0,0,0.15)', borderTop: `4pt solid ${theme.colors.purple}`}} bg="white" color="primary" p={4}>
          <Heading mb={2}>Spaceship Demo</Heading>
          <Text>Get a demo of Spaceships automation dashboard.</Text>
          <iframe title="Demo" src="https://player.vimeo.com/video/461250975" width="100%" height="480" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
        </Card>
      </Box>
    </Box>
  </>
);

export default HomeQuoteView;
