import React, { useEffect, useMemo, useState } from 'react';
import { CONFIG } from '../../Config';
import HomeQuoteView from './HomeQuote.view';


const HomeQuoteContainer = () => {
  const [start, setStart] = useState<Array<number>>([]);
  const [end, setEnd] = useState<Array<number>>([]);
  const [email, setEmail] = useState<string>();
  
  const [equip, setEquip] = useState<{[key: string]: boolean}>({van: false, flatbed: false, reefer: false})

  const [result, setResult] = useState<any>();
  const [resultState, setResultState] = useState<string>("INIT");

  useEffect(() => {
    const r = sessionStorage.getItem("truckist_quote")
    if (r) {
      setResult(JSON.parse(r));
      setResultState("LOADED");
    }
  }, []);

  const emailValid = useMemo(() => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = email != null && re.test(email.toLocaleLowerCase())
    return valid;
  }, [email]);
  
  const validLoc = useMemo(() => {
    const v = (start.length === 2 && start.every(x => Math.abs(x) > 0) && end.length === 2 && end.every(x => Math.abs(x) > 0))
    return v;
  }, [end, start]);

  const allValid = validLoc && emailValid;



  const setValues = (field: string, lat: number, lng: number) => {
    if(field === 'start') {
      setStart([lat, lng]);
    } else {
      setEnd([lat, lng])
    }
  }

  const selectEquip = (field: string) => {
    setEquip({...equip, [field]: !equip[field] });
  }

  const action = async () => {
    setResultState("LOADING")
    try {
      await fetch('https://api.hsforms.com/submissions/v3/integration/submit/7206071/2b862dcd-d66d-4ea2-be6e-de551d670ab0', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fields: [{ name: 'email', value: email}]}),
      })
    } catch(err) {
      console.error('email integration failed');
    }
    try {
      const res = await fetch(`${CONFIG.apiUrl}v1/quotes/price`,{
        method: "POST",
        body: JSON.stringify({
          start: { lat: start[0], lng: start[1]},
          end: { lat: end[0], lng: end[1]}
        })
      })
      if (res.ok) {
        const r = await res.json()
        if (r != null) {
          setResult(r);
          sessionStorage.setItem("truckist_quote", JSON.stringify(r));
          setResultState("LOADED")
        } else {
          setResultState("NULL");
        }
      }
    } catch {
      setResultState("ERROR")
    } 
  }

  return (
    <HomeQuoteView
      start={start}
      end={end}
      setValues={setValues}
      equip={equip}
      setEquip={selectEquip}
      submit={action}
      result={result}
      resultState={resultState}
      email={email}
      setEmail={setEmail}
      allValid={allValid}
      emailValid={emailValid}
    />
  );
};

export default HomeQuoteContainer;
